@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* For use outside of tailwind */
    --color-brand-green-50: #e8f7f1;
    --color-brand-green-100: #b9e5d4;
    --color-brand-green-200: #97d8bf;
    --color-brand-green-300: #67c7a2;
    --color-brand-green-400: #49bc90;
    --color-brand-green-500: #1cab74;
    --color-brand-green-600: #199c6a;
    --color-brand-green-700: #147952;
    --color-brand-green-800: #0f5e40;
    --color-brand-green-900: #0c4831;

    --color-brand-yellow-50: #f8f5ec;
    --color-brand-yellow-100: #ebdfc5;
    --color-brand-yellow-200: #e1cfa9;
    --color-brand-yellow-300: #d3b982;
    --color-brand-yellow-400: #caac6a;
    --color-brand-yellow-500: #bd9745;
    --color-brand-yellow-600: #ac893f;
    --color-brand-yellow-700: #866b31;
    --color-brand-yellow-800: #685326;
    --color-brand-yellow-900: #4f3f1d;

    --color-brand-grey-50: #ebebeb;
    --color-brand-grey-100: #c0c0c0;
    --color-brand-grey-200: #a1a1a1;
    --color-brand-grey-300: #767676;
    --color-brand-grey-400: #5c5c5c;
    --color-brand-grey-500: #333333;
    --color-brand-grey-600: #2e2e2e;
    --color-brand-grey-700: #242424;
    --color-brand-grey-800: #1c1c1c;
    --color-brand-grey-900: #151515;
    /* For use outside of tailwind */

    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body,
  html {
    @apply bg-background bg-[#fafafa] text-foreground overflow-x-hidden;
  }
}

.no-scroll {
  overflow: hidden;
}
